@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700);
.color-palette {
  background-color: #c7d1da;
  background-color: #b1a8bb;
  background-color: #635962;
  background-color: #5f6867;
  background-color: #7c947a;
  background-color: #cfd2bf;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}

/*     --breakpoint-sm: 576px; */
/*     --breakpoint-md: 768px; */
/*     --breakpoint-lg: 992px; */
/*     --breakpoint-xl: 1200px; */

.header {
  width: 100%;
}

.header__logo {
  width: 90%;
}

.header__navbar {
  padding: 0;
}

.nav-btn-pd {
  padding: 0 2rem 0 0;
}

.header__navbar--scaled {
  padding: 0 1rem;
}

.header__navbar--margin-top {
  margin: 2rem 0 0 0;
}

.header__brand-title {
  font-family: 'IBM Plex Sans', sans-serif;
  display: flex !important;
  align-items: center !important;
}

.header__brand-logo {
  width: 1.5rem;
}

.light-color:hover {
  color: #fff;
}

@media screen and (max-width: 770px) {
  .NavBar {
    flex-direction: column;
  }

  .nav-btn-pd {
    padding: 0;
  }

  .navbar-brand {
    margin: 0 !important;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
  }

  .header__brand-title {
    display: none !important;
  }

  .header__brand-phone {
    text-align: center;
    width: 100%;
  }

  .navbar-toggler {
    margin: 0 0 1rem 0;
  }
}

@media screen and (min-width: 960px) {
  .collapse .navbar-nav .nav-link {
    font-size: 1.2rem;
  }
}

.no-hover {
  color: rgba(0, 0, 0, 0.7) !important;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 1rem 0;
}

.copyright p {
  padding: 0;
  margin: 0.5rem 0 0 0;
  font-size: 0.9rem;
  color: lightgray;
  text-align: center;
}

.copyright-symbol {
  font-size: 1rem;
}
.card {
  min-width: 250px;
}

.info-card .min-width {
  min-width: 220px;
}

@media screen and (max-width: 840px) {
  .landing .col-tablet {
    flex-flow: column nowrap;
  }
}

.landing__notice {
  width: 100%;
  background-color: #f8f9fa;
  padding: 0 0 2rem 0;
}

.notice {
  position: relative;
  font-size: 1.5rem;
  margin: 0;
  width: 100%;
  padding: 0.5rem 10px;
  text-align: center;
  background-color: #5c897b;
  box-shadow: 0 10px 6px -6px #777;
}

.landing {
  background-color: #a0b7b3;
}

.background__banner {
  background-image: url(/static/media/nature-path-background.8055bc27.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner__overlay {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 2rem;
}

.banner {
  position: relative;
}

.banner__heading {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}

.banner__heading h3 {
  padding: 1rem;
}

.banner__heading--cards {
  flex-direction: column;
  justify-content: space-around;
  color: black;
  text-align: left;
  align-items: flex-start;
}

.banner__heading--cards .card {
  flex-basis: 50%;
}

.banner__img {
  width: 100%;
}

.bold-mission {
  color: #faceff;
}

.banner__call-to-action {
  display: block;
  margin: 1rem auto;
  padding: 1rem 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #66887c;
  border-radius: 5px;
  box-shadow: 0px 16px 12px -12px rgba(256, 256, 256, 0.4);
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
}

.banner__call-to-action:hover {
  box-shadow: 0px 16px 10px -10px rgba(256, 256, 256, 0.4);
  color: white;
}

@media screen and (max-width: 515px) {
  .banner__overlay h3 {
    font-size: 1.2rem;
    padding: 1rem;
  }

  .notice {
    font-size: 1.5rem;
  }
}

.about {
  background-color: #5c897b;
  padding: 0 5rem;
  border-radius: 15px, 0;
}

.about h2 {
  padding: 1rem 0 0rem 0;
  margin: 0;
}

.about__profiles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 1120px) {
  .about {
    border-radius: 0;
  }
}

@media screen and (max-width: 717px) {
  .about h2 {
    text-align: center;
    font-size: 1.5rem;
  }
}

.profile {
  background-color: #daefec;
  padding: 1rem;
  margin: 1rem 0 2rem 0;
  border-radius: 10px;
}

.profile__img {
  width: 300px;
  float: left;
  margin: 0 1.5rem 0 1rem;
  border-radius: 10px;
}

.profile__img--right {
  float: right;
  margin: 0 1rem 0 1.5rem;
}

.profile__heading {
  font-size: 3rem;
  margin: 0rem 0 0 0;
  padding: 0 1rem;
}

.profile__body {
  font-size: 1.5rem;
  padding: 1rem;
  margin: 0 0 0 0;
}

.profile__button {
  margin: 0 0 0 1rem;
}

.profile__appointment {
  text-decoration: none;
  font-size: 1.5rem;
  color: inherit;
}

.profile__appointment:hover {
  color: inherit;
}

@media screen and (max-width: 717px) {
  .profile__img {
    display: block;
    float: none;
    margin: 1rem auto;
    width: 150px;
  }

  .profile__heading {
    font-size: 1.5rem;
  }

  .profile__body {
    font-size: 1rem;
  }
}

.contact {
  padding-bottom: 5rem;
  width: 70%;
  margin: auto;
}

.parking {
  margin: 0 2rem;
}

.appt-btn {
  text-decoration: none;
  background-color: #66887c;
  color: white;
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0px 16px 10px -10px rgba(0, 0, 0, 0.4);
}

.appt-btn:hover {
  color: #deeeec;
}

@media screen and (max-width: 620px) {
  .contact {
    width: 95%;
    margin: auto;
  }
}
.insurances {
  border-bottom: 1px solid lightgray;
  padding: 5rem 5rem 10rem 5rem;
  width: 75%;
  margin: auto;
}

@media screen and (max-width: 620px) {
  .insurances {
    padding: 1rem;
    width: 95%;
  }
}
.resources {
  width: 75%;
  padding: 2rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 700px) {
  .resources {
    padding: 0.5rem;
    margin: 0;
  }

  .resources .table-sm tr {
    display: flex;
    flex-direction: column;
    border: medium none currentColor;
    border: initial;
  }

  .resources .table-sm td {
    border-top: 0 !important;
  }
}
.fees {
  border-bottom: 1px solid lightgray;
  padding: 5rem 5rem 10rem 5rem;
  width: 75%;
  margin: auto;
}

@media screen and (max-width: 620px) {
  .fees {
    padding: 2rem;
    width: 95%;
  }
}
.App {
  min-width: 320px;
  width: 90%;
  margin: auto;
  background-color: white;
}

@media screen and (max-width: 1120px) {
  .App {
    width: 100%;
  }
}

