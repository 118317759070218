@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,700');

.color-palette {
  background-color: #c7d1da;
  background-color: #b1a8bb;
  background-color: #635962;
  background-color: #5f6867;
  background-color: #7c947a;
  background-color: #cfd2bf;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}

/*     --breakpoint-sm: 576px; */
/*     --breakpoint-md: 768px; */
/*     --breakpoint-lg: 992px; */
/*     --breakpoint-xl: 1200px; */
