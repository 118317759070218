.App {
  min-width: 320px;
  width: 90%;
  margin: auto;
  background-color: white;
}

@media screen and (max-width: 1120px) {
  .App {
    width: 100%;
  }
}
